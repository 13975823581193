<template>
  <div>
    <div class="crumbs">
      <a href="###">首页</a>
      <label>></label>
      <a href="###">借款申请</a>
      <label>></label>
      <a href="###">货款保证金贷款详情</a>
    </div>

    <div class="wrap-form-group">
      <el-form
        class="wrap-form"
        size="small"
        label-width="100px"
        label-position="left"
      >
        <el-form-item label="标地信息">
          <!-- <div class="input-row">
            <el-input placeholder="请输入标地信息"></el-input>
            <el-button type="primary">选择</el-button>
          </div> -->
          <!-- <el-select v-model="form.bidProjectId" placeholder="请选择标的信息">
            <el-option
              v-for="item in bidProjectList"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select> -->
          <span>{{ selectedBidProject.name }}</span>
        </el-form-item>
        <template v-if="this.form.bidProjectId">
          <el-form-item label="采购方">
            <!-- <div class="input-row">
            <el-input placeholder="请选择采购方"></el-input>
            <el-button type="primary">选择</el-button>
          </div> -->

            <span>{{ selectedBidProject.purchaseOrg }}</span>
          </el-form-item>
          <el-form-item label="项目名称">
            <!-- <el-input placeholder="请输入项目名称"></el-input> -->
            <span>{{ selectedBidProject.name }}</span>
          </el-form-item>
          <el-form-item label="招标机构">
            <!-- <el-input placeholder="请输入招标机构"></el-input> -->
            <span>{{ selectedBidProject.bidOrg }}</span>
          </el-form-item>
          <el-form-item label="代码">
            <!-- <el-input placeholder="请输入招标代码"></el-input> -->
            <span>{{ selectedBidProject.bidCode }}</span>
          </el-form-item>
        </template>

        <el-form-item label="中标金额">
          <span>{{ form.googsMoney }}元</span>
        </el-form-item>

        <el-form-item label="回款周期">
          <span>{{ form.returnPeriod }}</span>
        </el-form-item>

        <el-form-item label="借款金额">
          <span>{{ form.loanMoney }}元</span>
        </el-form-item>
        <el-form-item label="供货方公司">
          <div class="input-row">
            <span>{{ form.productCompany }}</span>
          </div>
        </el-form-item>
      </el-form>
      <div>
        <el-table :data="form.detailList" size="small" class="qh-table">
          <el-table-column label="产品">
            <template slot-scope="scope">
              <span>{{ scope.row.productName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="价格">
            <template slot-scope="scope">
              <span>{{ scope.row.productPrice }}元</span>
            </template>
          </el-table-column>
          <el-table-column label="数量">
            <template slot-scope="scope">
              <span>{{ scope.row.productAmount }}元</span>
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: right; margin-top: 20px">
          <!-- <div>
            <el-button type="text" @click="addDetailRow">继续添加</el-button>
          </div> -->
          <div>总价：{{ totalPrice }}元</div>
        </div>
      </div>
      <div style="text-align: center; margin-top: 69px">
        <el-button @click="cancel">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import qs from "qs";
export default {
  data() {
    return {
      bidProjectList: [],
      form: {
        bidProjectId: "",

        googsMoney: "",
        returnPeriod: "",
        loanMoney: "",
        productCompany: "",
        detailList: [], // 产品列表 productName  productPrice  productAmount
      },
    };
  },
  computed: {
    selectedBidProject() {
      let list = this.bidProjectList.filter(
        (item) => item.id == this.form.bidProjectId
      );
      return list.length > 0 ? list[0] : {};
    },
    totalPrice() {
      let total = 0;
      this.form.detailList.forEach((item) => {
        let price = item.productPrice * item.productAmount;
        total += price;
      });
      return total;
    },
  },
  mounted() {
    this.initForm();
  },
  methods: {
    initForm() {
      this.getBidProjectList();
      let id = this.$route.params.id;
      if (id > 0) {
        this.getDetail();
      } else {
        this.form = {
          bidProjectId: "",

          googsMoney: "",
          returnPeriod: "",
          loanMoney: "",
          productCompany: "",
          detailList: [], // 产品列表 productName  productPrice  productAmount
        };
      }
    },
    getDetail() {
      let id = this.$route.params.id;
      this.$axios
        .get(`/goodsMoneyLoan/seachGoodsMoneyLoanById/${id}`)
        .then((res) => {
          if (res.state == 200) {
            this.form = {
              bidProjectId: res.data.goodsMoneyLoan.bidProjectId,
              memberId: res.data.goodsMoneyLoan.memberId,
              googsMoney: res.data.goodsMoneyLoan.googsMoney,
              returnPeriod: res.data.goodsMoneyLoan.returnPeriod,
              loanMoney: res.data.goodsMoneyLoan.loanMoney,
              productCompany: res.data.goodsMoneyLoan.productCompany,
              goodsOrderNo: res.data.goodsMoneyLoan.goodsOrderNo,
              detailList: res.data.goodsMoneyLoanDetail,
            };
          }
        });
    },
    cancel() {
      this.$router.back();
    },

    getBidProjectList() {
      this.$axios.get("/bidProject/getBidProjectList").then((res) => {
        if (res.state == 200) {
          this.bidProjectList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  watch: {
    "$route.params.id": function () {
      this.initForm();
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap-form-group {
  width: 941px;
  border: 1px solid #ededed;
  padding: 64px;
  box-sizing: border-box;
  .wrap-form {
    width: 350px;

    .input-row {
      display: flex;
    }
  }
}
</style>