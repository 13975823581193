<template>
  <div>
    <div class="crumbs">
      <a href="###">首页</a>
      <label>></label>
      <a href="###">投标项目维护</a>
      <label>></label>
      <a href="###">采购金贷款还款</a>
      <label>></label>
      <a href="###">上传还款凭证</a>
    </div>

    <div class="wrap-form">
      <el-form
        size="small"
        label-width="150px"
        label-position="left"
        :model="form"
        ref="form"
        :rules="rules"
      >
        <el-form-item label="招标机构">{{ list.bidOrg }}</el-form-item>
        <el-form-item label="项目名称">{{ list.name }}</el-form-item>
        <el-form-item label="招标金额"
          >{{ list.bidMoney | currency }} 万元</el-form-item
        >
        <el-form-item label="统一社会信用代码">{{ list.bidCode }}</el-form-item>
        <el-form-item label="中标信息">
          {{
            { 0: "投标中", 1: "中标", 2: "未中标" }[list.bidStatus]
          }}</el-form-item
        >
        <el-form-item label="贷款金额"
          >{{ list.gfinalMoney | currency }} 万元</el-form-item
        >
        <el-form-item label="还款金额" prop="returnMoney">
          <el-input v-model="form.returnMoney" placeholder="请输入还款金额">
            <div slot="suffix">万元</div>
          </el-input>
        </el-form-item>
        <el-form-item label="还款时间" prop="returnDate">
          <el-input
            v-model="form.returnDate"
            placeholder="请输入还款时间"
            type="date"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="交易流水号" prop="transNum">
          <el-input v-model="form.transNum" placeholder="请输入交易流水号">
          </el-input>
        </el-form-item>
        <el-form-item label="还款凭证" prop="returnCertF">
          <input
            type="file"
            ref="upload"
            @change="onSelectFile"
            hidden
            accept=".pdf"
          />
          <el-button type="text" v-if="files.marginCertF">{{
            files.marginCertF ? files.marginCertF.name : ""
          }}</el-button>
          <el-button type="primary" @click="$refs.upload.click()"
            >上传</el-button
          >
        </el-form-item>
      </el-form>
      <div style="text-align: center; margin-top: 69px">
        <el-button type="primary" @click="applyBidRefund">提交</el-button>
        <el-button @click="cancel">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    var returnMoney_rule = (rule, value, callback) => {
      if (Number(value) && Number(value) % 1 == 0) {
        callback();
      } else {
        callback(new Error("请输入还款金额(整数型)"));
      }
    };
    return {
      list: {},
      form: {
        bidProjectId: "",
        returnCertF: "", // 退款凭证
        returnMoney: "",
        returnDate: "",
        transNum: "",
      },
      files: {
        marginCertF: null,
      },
      rules: {
        returnCertF: [
          {
            message: "请选择还款凭证",
            required: true,
          },
        ],
        returnDate: [
          {
            message: "请输入还款时间",
            required: true,
          },
        ],
        returnMoney: [
          {
            validator: returnMoney_rule,
            trigger: "blur",
            required: true,
          },
        ],
        transNum: [
          {
            message: "请输入交易流水号",
            required: true,
          },
        ],
      },
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    onSelectFile(e) {
      let files = e.target.files;
      if (files.length == 0) return;
      this.files.marginCertF = files[0];
      e.target.value = "";
    },
    getDetail() {
      let id = this.$route.params.id;
      this.$axios.get(`/bidProject/bidProjectDetails/${id}`).then((res) => {
        if (res.state == 200) {
          this.list = {
            name: res.data.bidProject.name,
            bidOrg: res.data.bidProject.bidOrg,
            bidCode: res.data.bidProject.bidCode,
            bidStatus: res.data.bidProject.bidStatus,
            bidMoney: res.data.bidProject.bidMoney,
            gfinalMoney:
              res.data.goodsMoneyLoanAndList.goodsMoneyLoan.finalMoney,
          };
        }
      });
    },
    async applyBidRefund() {
      let _this = this;
      _this.form.bidProjectId = _this.$route.params.id;
      _this.form.returnCertF = _this.files.marginCertF;
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let data = {
            bidProjectId: _this.form.bidProjectId,
            returnCertF: _this.form.returnCertF,
            returnDate: _this.form.returnDate,
            returnMoney: _this.form.returnMoney,
            transNum: _this.form.transNum,
          };
          var formdata = new FormData();
          Object.keys(data).forEach((key) => {
            formdata.set(key, data[key]);
          });
          await _this.$axios
            .post("/goodsMoneyLoan/sendBackGoodsMoneyLoan", formdata)
            .then((res) => {
              if (res.state == 200) {
                this.$message.success(res.message);
                _this.$router.back();
              } else {
                this.$message.error(res.message);
              }
            });
        }
      });
    },
    cancel() {
      this.$router.back();
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap-form {
  width: 941px;
  // height: 557px;
  border: 1px solid #ededed;
  padding: 64px;
  box-sizing: border-box;
  margin-bottom: 50px;
}
.el-form-item__content {
  .el-input {
    width: 300px;
  }
}
</style>